<template>
  <div class="flex items-end">
    <div class="flex-1">
      <selectTree
        :data="list"
        :value="predefinedAudience"
        v-bind="$props"
        :props="{
          children: 'childList',
          label: 'nameDesc',
          disabled: 'disabled',
        }"
        v-loading="loading"
        checkStrictly
        ref="selectTree"
        @change="onTreeChange"
        @check="onCheck"
      >
        <template slot-scope="{ node, data }">
          <div class="flex justify-between items-center w-full">
            <div>{{ node.label }}</div>
            <div v-if="!node.isLeaf">
              <el-button
                v-if="!isSelectAll(data.allChildIds)"
                type="text"
                @click.stop="selectAllLeaf(node, data)"
                >选择全部</el-button
              >
              <el-button
                v-else
                type="text"
                @click.stop="deleteAllLeaf(node, data)"
                >取消全部</el-button
              >
            </div>
          </div>
        </template>
      </selectTree>
    </div>
    <div class="mx-3">
      <el-select
        placeholder="选择常用"
        v-model="interestLabelQuery"
        value-key="id"
        filterable
        clearable
        multiple
        @change="changeInterestLabel"
        :loading="loadingLabel"
      >
        <el-option
          v-for="item in interestLabelQueryList"
          :label="item.label"
          :value="item"
        >
          <div class="flex w-full items-center justify-between">
            <div>{{ item.label }}</div>
            <div @click.stop="deleteLabel(item.id)">
              <i class="el-icon-delete text-zinc-700 hover:text-primary"></i>
            </div>
          </div>
        </el-option>
      </el-select>
    </div>

    <el-button
      type="text"
      :disabled="predefinedAudience.length == 0 || !countryCodes || countryCodes.length == 0"
      @click="showIntersLabel = true"
      >存为常用</el-button
    >
    <interstLabelSave
      :show.sync="showIntersLabel"
      @close="getInterestLabelQueryList"
      :API="interestLabelSaveAPI"
      :params="{
        userId: userId,
        countryCodes: countryCodes,
        adAccountId: adAccountId,
        interestIds: predefinedAudience,
      }"
    ></interstLabelSave>
  </div>
</template>

<script>
import { interstLabelSave, selectTree, selectTreePropsType } from '@adCommonComponent';
import { interestLabelQuery } from '@/api/addGoods';
import { interestLabelDel } from '@/api/snapchat/create.js';

import { mapState } from 'vuex';
import _ from 'lodash';
export default {
  props: {
    ...selectTreePropsType,
    value: Object,
    props: {
      default: () => ({ children: 'childList', label: 'name' }),
    },
    countryCodes: Array, // 国家列表
    adAccountId: String, // 账户id
    interestLabelQueryAPI: Function,
    interestLabelSaveAPI: Function, // 保存常用接口
    list: Array,
  },
  components: { selectTree, interstLabelSave },
  computed: {
    ...mapState({
      userId: (state) => state.user.userInfo.data.id,
    }),
    predefinedAudience() {
      const values = Object.values(this.value || {});
      return Array.from(new Set([].concat(...values)));
    },
  },
  data() {
    return {
      loading: false,
      interestLabelQuery: [], // 选择的常用
      showIntersLabel: false,
      interestLabelQueryList: [], // 常用列表
      loadingLabel: false,
    };
  },
  methods: {
    isSelectAll(list) {
      const intersection = _.intersection(list, this.predefinedAudience);
      return intersection.length == list.length;
    },
    // 点击勾选全部
    selectAllLeaf(node, data) {
      if (this.$refs.selectTree.$refs.elTree) {
        this.onChange({
          ...this.value,
          custom: [...this.value.custom, ...data.allChildIds],
        });
      }
    },
    // 取消勾选全部
    deleteAllLeaf(node, data) {
      let newValue = {};
      Object.keys(this.value).forEach((ele) => {
        newValue[ele] = _.without(this.value[ele], ...data.allChildIds);
      });
      this.onChange(newValue);
    },

    // 选择常用
    changeInterestLabel(value) {
      if (!value) return;
      let ids = {};
      value.forEach((element) => {
        ids[element.id] = element.labelRelations.map((item) => item.id);
      });
      this.onChange({
        custom: this.value.custom,
        ...ids,
      });
    },
    onTreeChange(value) {
      let newValue = {};
      const keys = Object.keys(this.value);
      keys.forEach((key) => {
        newValue[key] = _.without(this.value[key], ..._.difference(this.value[key], value));
      });
      this.onChange(newValue);
    },
    onCheck({ checkedKeys, checked, data }) {
      let newValue;
      if (checked) {
        newValue = { ...this.value };
        newValue.custom = [...newValue.custom, data.id];
      } else {
        const keys = Object.keys(this.value);
        newValue = {};
        keys.forEach((key) => {
          newValue[key] = _.without(this.value[key], data.id);
        });
      }
      this.onChange(newValue);
    },
    onChange(value) {
      this.$emit('input', value);
    },
    // 常用列表
    getInterestLabelQueryList() {
      if (!this.countryCodes || !this.countryCodes.length || !this.adAccountId) {
        this.interestLabelQueryList = [];
        this.interestLabelQuery = [];
        return;
      }
      let params = {
        countryCodes: this.countryCodes, // 产品id
        adAccountId: this.adAccountId,
      };
      this.loadingLabel = true;
      interestLabelQuery(params)
        .then((res) => {
          this.interestLabelQueryList = res.data;
        })
        .finally(() => {
          this.loadingLabel = false;
        });
    },
    deleteLabel(id) {
      let data = {
        id,
      };
      interestLabelDel(data).then((res) => {
        if (res.code == 0) {
          const index = this.interestLabelQuery.findIndex((ele) => ele.id == id);
          if (index >= 0) {
            this.interestLabelQuery.splice(index, 1);
          }
          this.getInterestLabelQueryList();
        }
      });
    },
  },
  mounted() {
    this.getInterestLabelQueryList();
  },
  watch: {
    adAccountId() {
      this.getInterestLabelQueryList();
    },
    countryCodes: {
      deep: true,
      immediate: true,
      handler(val) {
        this.getInterestLabelQueryList();
      },
    },
  },
};
</script>
