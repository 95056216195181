<template>
  <div>
    <div
      class="!border border-[#DCDFE6] border-solid rounded-xl w-full p-2 mb-1"
      v-show="nodes.length"
    >
      <div
        v-for="(node, index) in nodes"
        :key="node.id"
        class="text-sm text-zinc-700 flex justify-between px-3 py-1 hover:bg-zinc-100"
      >
        <div>
          <i
            class="el-icon-circle-check text-success"
            v-show="includeList.includes(node.id)"
          ></i>
          <i
            class="el-icon-info !text-danger !ml-0"
            v-show="excludeList.includes(node.id)"
          ></i>
          <span class="ml-2">{{ node.name }}</span>
        </div>

        <i
          class="el-icon-close justify-end leading-none hover:text-primary"
          @click="deleteItem(node)"
        ></i>
      </div>
    </div>
    <selectTree
      :data="list"
      :props="props"
      :value="selectList"
      :showCheckbox="false"
      :showTag="false"
      @getData="getData"
    >
      <template slot-scope="{ node, data }">
        <div
          class="flex justify-between items-center w-full"
          @click="includeItem(node, data)"
        >
          <div>{{ node.label }}-{{ node.isLeaf }}</div>
          <div v-if="node.isLeaf">
            <el-button
              type="text"
              v-show="!includeList.includes(data.id)"
              @click.stop="includeItem(node, data)"
              >包含</el-button
            >
            <el-button
              type="text"
              v-show="!excludeList.includes(data.id)"
              @click.stop="excludeItem(node, data)"
              >不包含</el-button
            >
          </div>
        </div>
      </template>
    </selectTree>
  </div>
</template>
<script>
import { selectTree } from '@adCommonComponent';
export default {
  components: {
    selectTree,
  },
  props: {
    value: Array,
    list: {
      // 数据
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      includeList: [], // 包含列表
      excludeList: [], // 不包含列表
      props: {
        label: 'name',
        children: 'list',
      },
      nodes: [],
    };
  },
  computed: {
    // 选择的列表
    selectList() {
      return [...this.includeList, ...this.excludeList];
    },
  },
  methods: {
    includeItem(node, data) {
      this.includeList.push(data.id);
      const index = this.excludeList.findIndex((item) => item == data.id);
      if (index >= 0) {
        this.excludeList.splice(index, 1);
      }
      this.onInput();
    },
    excludeItem(node, data) {
      this.excludeList.push(data.id);
      const index = this.includeList.findIndex((item) => item == data.id);
      if (index >= 0) {
        this.includeList.splice(index, 1);
      }
      this.onInput();
    },
    getData(val, nodes) {
      this.nodes = nodes;
    },
    onInput() {
      let newValue = [
        {
          ...this.value[0],
          segmentId: this.includeList,
        },
        {
          ...this.value[1],
          segmentId: this.excludeList,
        },
      ];
      this.$emit('input', newValue);
    },
    deleteItem(node) {
      const includeIndex = this.includeList.findIndex((item) => item == node.id);
      const excludeIndex = this.excludeList.findIndex((item) => item == node.id);

      if (includeIndex >= 0) {
        this.$delete(this.includeList, includeIndex);
      }
      if (excludeIndex >= 0) {
        this.$delete(this.excludeList, excludeIndex);
      }
      this.onInput();
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        const [includeObj, excludeObj] = this.value;
        this.includeList = includeObj.segmentId;
        this.excludeList = excludeObj.segmentId;
        // this.selectList = [...this.includeList, ...this.excludeList];
      },
    },
  },
};
</script>
