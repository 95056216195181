import md5 from 'md5';

// 获取随机数
const getNonce = (length) => {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
};

export const getHeader = (URI = '/api/media/upload/single') => {
  const timestamp = Math.floor(new Date().getTime() / 1000);
  const client_secret = process.env.VUE_APP_CLIENT_SECRET;
  const nonce = getNonce(5);
  const result1 = md5(`POST${URI}${client_secret}`);
  const signature = md5(`${timestamp}${nonce}${result1}${client_secret}`);
  return {
    client_id: process.env.VUE_APP_CLIENT_ID,
    timestamp,
    signature,
    nonce,
  };
};
